// GET All EXPERIENCE
export const GET_EXPERIENCE_REQUEST = "GET_EXPERIENCE_REQUEST";
export const GET_EXPERIENCE_SUCESSFUL = "GET_EXPERIENCE_SUCESSFUL";
export const GET_EXPERIENCE_FAIL = "GET_EXPERIENCE_FAIL";

// POST EXPERIENCE

export const POST_EXPERIENCE_REQUEST = "POST_EXPERIENCE_REQUEST";
export const POST_EXPERIENCE_SUCESSFUL = "POST_EXPERIENCE_SUCESSFUL";
export const POST_EXPERIENCE_FAIL = "POST_EXPERIENCE_FAIL";

// UPDATE EXPERIENCE

export const UPDATE_EXPERIENCE_REQUEST = "UPDATE_EXPERIENCE_REQUEST";
export const UPDATE_EXPERIENCE_SUCESSFUL = "UPDATE_EXPERIENCE_SUCESSFUL";
export const UPDATE_EXPERIENCE_FAIL = "UPDATE_EXPERIENCE_FAIL";

// DELETE EXPERIENCE

export const DELETE_EXPERIENCE_REQUEST = "DELETE_EXPERIENCE_REQUEST";
export const DELETE_EXPERIENCE_SUCESSFUL = "DELETE_EXPERIENCE_SUCESSFUL";
export const DELETE_EXPERIENCE_FAIL = "DELETE_EXPERIENCE_FAIL";

// Single Experice

export const GET_SINGLE_EXPERIENCE_REQUEST = "GET_SINGLE_EXPERIENCE_REQUEST";
export const GET_SINGLE_EXPERIENCE_SUCESSFUL = "GET_SINGLE_EXPERIENCE_SUCESSFUL";
export const GET_SINGLE_EXPERIENCE_FAIL = "GET_SINGLE_EXPERIENCE_FAIL";


// Archive

export const ARCHIVE_EXPERIENCE_REQUEST = "ARCHIVE_EXPERIENCE_REQUEST";
export const ARCHIVE_EXPERIENCE_SUCCESS = "ARCHIVE_EXPERIENCE_SUCCESS";
export const ARCHIVE_EXPERIENCE_FAIL = "ARCHIVE_EXPERIENCE_FAIL";

// unarchive experice

export const UNARCHIVE_EXPERIENCE_REQUEST = "UNARCHIVE_EXPERIENCE_REQUEST";
export const UNARCHIVE_EXPERIENCE_SUCCESS = "UNARCHIVE_EXPERIENCE_SUCCESS";
export const UNARCHIVE_EXPERIENCE_FAIL = "UNARCHIVE_EXPERIENCE_FAIL";

// Experice Media and Schedule Updates

export const UPLOAD_MEDIA_SCHEDULED_REQUEST = "UPLOAD_MEDIA_SCHEDULED_REQUEST"
export const UPLOAD_MEDIA_SCHEDULED_SUCESS = "UPLOAD_MEDIA_SCHEDULED_SUCESS"
export const UPLOAD_MEDIA_SCHEDULED_FAIL = "UPLOAD_MEDIA_SCHEDULED_FAIL"



// add reviwes

export const  ADD_NEW_REVIWES_REQUEST = 'ADD_NEW_REVIWES_REQUEST';
export const  ADD_NEW_REVIWES_SUCCESSFUL = 'ADD_NEW_REVIWES_SUCCESSFUL';
export const  ADD_NEW_REVIWES_ERROR = 'ADD_NEW_REVIWES_ERROR';

// Make the schedule Available

export const MAKE_AVAILABLESCHEDULE_REQUEST = 'MAKE_AVAILABLESCHEDULE_REQUEST';
export const MAKE_AVAILABLESCHEDULE_SUCCESS = 'MAKE_AVAILABLESCHEDULE_SUCCESS';
export const MAKE_AVAILABLESCHEDULE_FAIL = 'MAKE_AVAILABLESCHEDULE_FAIL';
// Make the schedule Uuavailable

export const MAKE_UNAVAILABLESCHEDULE_REQUEST = 'MAKE_UNAVAILABLESCHEDULE_REQUEST';
export const MAKE_UNAVAILABLESCHEDULE_SUCCESS = 'MAKE_UNAVAILABLESCHEDULE_SUCCESS';
export const MAKE_UNAVAILABLESCHEDULE_FAIL = 'MAKE_UNAVAILABLESCHEDULE_FAIL';
