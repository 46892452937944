import './App.css';
import { Box, Flex } from '@chakra-ui/react';
import AllRouter from './router/AllRouter';
import Navbar1 from './components/Navbar1';

function App() {
  return (
    <Flex flexDirection={"column"} gap={15}>
      <Box>
        <Navbar1 />
      </Box>

      <Box mt={20}>
        <AllRouter />
      </Box>
    </Flex>
  );
}

export default App;
