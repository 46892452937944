import axios from 'axios';
import {
  USER_APPROVAL_VERIFY_SUCCESS,
  REMOVE_REPORT_SUCCESS,
  DELETE_USER_SUCCESS,
  RESET_USERS,
  RESET_REPORTED_USERS,
  GET_ALL_USERS_FOR_VERIFY_REQUEST,
  GET_ALL_USERS_FOR_VERIFY_SUCCESS,
  GET_ALL_USERS_FOR_VERIFY_FAILURE,
  USER_APPROVAL_VERIFY_REQUEST,
  USER_APPROVAL_VERIFY_FAILURE,
  USER_VERIFY_REJECTION_REQUEST,
  USER_VERIFY_REJECTION_SUCCESS,
  USER_VERIFY_REJECTION_FAILURE,
  REMOVE_REPORT_REQUEST,
  REMOVE_REPORT_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  GET_ALL_REPORTED_USER_REQUEST,
  GET_ALL_REPORTED_USER_SUCCESS,
  GET_ALL_REPORTED_USER_FAILURE,
  GET_PROFILE_FOR_VERIFY_REQUEST,
  GET_PROFILE_FOR_VERIFY_SUCCESS,
  GET_PROFILE_FOR_VERIFY_FAILURE,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_FAILURE,
  SUBSCRIBE_USER_SUCCESS,
} from './actionTypes';

const api = process.env.REACT_APP_API_USER;

// FOR  GETTING UnVerified USER DATA
export const getUser = (gender, token) => dispatch => {
  dispatch({ type: GET_ALL_USERS_FOR_VERIFY_REQUEST });

  // Set up the Axios request headers with the token
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const queryParam = gender ? `?gender=${gender}` : '';

  return axios
    .get(`${api}/unverified-users${queryParam}`, { headers })
    .then(res => {
      if (Array.isArray(res.data.data)) {
        dispatch({ type: GET_ALL_USERS_FOR_VERIFY_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: GET_ALL_USERS_FOR_VERIFY_FAILURE });
      }
    })
    .catch(() => {
      dispatch({ type: GET_ALL_USERS_FOR_VERIFY_FAILURE });
    });
};

// FOR  GETTING REPORTED USER DATA
export const getAllReportedData = token => dispatch => {
  dispatch({ type: GET_ALL_REPORTED_USER_REQUEST });

  // Set up the Axios request headers with the token
  // console.log(token, `token`);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return (
    axios
      .get(`${api}/reported-users`, { headers })
      // .get(`${api}/user/reported-users`)
      .then(res => {
        // console.log(res.data)
        dispatch({ type: GET_ALL_REPORTED_USER_SUCCESS, payload: res.data });
      })
      .catch(() => {
        dispatch({ type: GET_ALL_REPORTED_USER_FAILURE });
      })
  );
};

// POST REQUEST FOR APPROVAL User
export const approval_for_verifying_User = (data, token, toast) => dispatch => {
  dispatch({ type: USER_APPROVAL_VERIFY_REQUEST });
  // console.log(token);
  axios
    .post(` ${api}/approve-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      dispatch({ type: USER_APPROVAL_VERIFY_SUCCESS, payload: res.data });
      toast({
        title: 'User Is Verified',
        description: 'User is verified successfully',
        status: 'success',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    })
    .catch(err => {
      dispatch({ type: USER_APPROVAL_VERIFY_FAILURE });
      toast({
        title: 'Something went wrong',
        description: 'User failed to verify',
        status: 'error',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    });
};

// POST REQUEST FOR UnAPPROVE User
export const rejection_for_verifying_User = (data, token, toast) => dispatch => {
  // const data = { userId }
  dispatch({ type: USER_VERIFY_REJECTION_REQUEST });
  return axios
    .post(`${api}/unapprove-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      // console.log(res.data)
      dispatch({ type: USER_VERIFY_REJECTION_SUCCESS });
      toast({
        title: 'User Is Unverified',
        description: 'User is unverified successfully',
        status: 'warning',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    })
    .catch(err => {
      dispatch({ type: USER_VERIFY_REJECTION_FAILURE });
      toast({
        title: 'Something went wrong',
        description: 'User failed to unverify',
        status: 'error',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    });
};

// POST REQUEST FOR REMOVE REPORT
export const remove_report = (token, data, toast) => dispatch => {
  console.log(data);
  dispatch({ type: REMOVE_REPORT_REQUEST });
  return axios
    .post(`${api}/remove-report`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      // console.log(res.data)
      dispatch({ type: REMOVE_REPORT_SUCCESS, payload: res.data });
      toast({
        title: 'Report Is Deleted',
        description: 'Report is deleted successfully',
        status: 'info',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    })
    .catch(err => {
      dispatch({ type: REMOVE_REPORT_FAILURE });
      toast({
        title: 'Something went wrong',
        description: 'Report failed to delete',
        status: 'error',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    });
};

// POST REQUEST FOR DELETE USER
export const deleteUser = (token, data, toast) => dispatch => {
  dispatch({ type: DELETE_USER_REQUEST });
  return axios
    .post(`${api}/delete-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => {
      // console.log("deleteUser",JSON.stringify(res.data))
      dispatch({ type: DELETE_USER_SUCCESS, payload: res.data });
      toast({
        title: 'User Is Deleted',
        description: 'User is deleted successfully',
        status: 'success',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    })
    .catch(err => {
      dispatch({ type: DELETE_USER_FAILURE });
      toast({
        title: 'Something went wrong',
        description: 'User failed to delete',
        status: 'error',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    });
};

export const resetUsers = userId => ({
  type: RESET_USERS,
  payload: userId,
});

export const resetReportedUsers = userId => ({
  type: RESET_REPORTED_USERS,
  payload: userId,
});

export const getProfile = (userId, token) => dispatch => {
  dispatch({ type: GET_PROFILE_FOR_VERIFY_REQUEST });

  // Set up the Axios request headers with the token
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${api}/${userId}`, { headers })
    .then(res => {
      console.log(res.data);
      if (res.data) {
        dispatch({ type: GET_PROFILE_FOR_VERIFY_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: GET_PROFILE_FOR_VERIFY_FAILURE, payload: res });
      }
    })
    .catch(() => {
      dispatch({ type: GET_PROFILE_FOR_VERIFY_FAILURE });
    });
};

export const updateSubscription = ({ userId, subscribe }, token, toast) => dispatch => {
  dispatch({ type: SUBSCRIBE_USER_REQUEST });

  // Set up the Axios request headers with the token
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const data = {
    userId,
    subscribe,
  }

  const subscriptionText = subscribe ? 'Subscribed' : 'Unsubscribed';
  const activationText = subscribe ? 'Activated' : 'Deactivated';
  console.log(data)
  return axios
    .put(`${api}/update-subscription`, data, { headers })
    .then(res => {
      console.log(res.data);
      if (res.data) {
        dispatch({ type: SUBSCRIBE_USER_SUCCESS, payload: res.data });
        toast({
          title: `User Is ${subscriptionText}`,
          description: `Subscription is ${activationText} successfully`,
          status: 'success',
          isClosable: true,
          position: 'top',
          autoClose: 2000,
        });
      } else {
        dispatch({ type: SUBSCRIBE_USER_FAILURE, payload: res });
        toast({
          title: 'Failed',
          description: 'Subscription update failed',
          status: 'error',
          isClosable: true,
          position: 'top',
          autoClose: 2000,
        });
      }
    })
    .catch(() => {
      dispatch({ type: SUBSCRIBE_USER_FAILURE });
      toast({
        title: 'Failed',
        description: 'Subscription update failed',
        status: 'error',
        isClosable: true,
        position: 'top',
        autoClose: 2000,
      });
    });
};