// get all booling

export const GET_ALL_BOOKINGDATA_REQUEST = "GET_ALL_BOOKINGDATA_REQUEST";
export const GET_ALL_BOOKINGDATA_SUCESS = "GET_ALL_BOOKINGDATA_SUCESS";
export const GET_ALL_BOOKINGDATA_FAIL = "GET_ALL_BOOKINGDATA_FAIL";


// booking status

export const EDIT_BOOKINGSTATUS_REQUEST = "EDIT_BOOKINGSTATUS_REQUEST";
export const EDIT_BOOKINGSTATUS_SUCESS = "EDIT_BOOKINGSTATUS_SUCESS";
export const EDIT_BOOKINGSTATUS_FAIL = "EDIT_BOOKINGSTATUS_FAIL";