import React, { useEffect } from 'react';
import TableCard from './TableCard';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../redux/userReducer/action';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const FemalePage = () => {
  const dispatch = useDispatch();
  const { users } = useSelector(store => store.userReducer);
  // console.log({"female":users});
  const { accessToken } = useSelector(state => state.authReducer);
  const navigation = useNavigate();
  const { userIsError } = useSelector(store => store.userReducer);
  useEffect(() => {
    dispatch(getUser('Female', accessToken)).then(res => {
      if (userIsError) {
        navigation('/login');
      }
    });
  }, [dispatch, accessToken, userIsError, navigation]);

  return (
    <Box>
      <TableCard users={users} />
    </Box>
  );
};

export default FemalePage;
