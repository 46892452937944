import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateSubscription } from '../../redux/userReducer/action';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Text,
  VStack,
  HStack,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { PageLoader } from '../../components/PageLoader';
import { approval_for_verifying_User, rejection_for_verifying_User, resetUsers } from '../../redux/userReducer/action';

const ProfilePage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const [isApproveDialogOpen, setApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [isSubscribeDialogOpen, setSubscribeDialogOpen] = useState(false);
  const cancelRef = React.useRef(null);
  const { profileDetails, profileDetailsLoading, profileDetailsError } = useSelector(store => store.userReducer);
  const { accessToken } = useSelector(state => state.authReducer);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();
  useEffect(() => {
    dispatch(getProfile(userId, accessToken)).then(res => {
     });
  }, [dispatch, accessToken, navigation, userId]);
  
  const [isVerified, setIsVerified] = useState(null);
  // const [isSubscribed, setIsSubscribed] = useState(null);

  const handleApproval = () => {

    // Dispatch the approval action
    dispatch(approval_for_verifying_User({ userId: userId }, accessToken, toast));

    // Dispatch the resetUsers action
    dispatch(resetUsers(userId));
    setIsVerified(true)
    // Display a toast message
    closeApproveDialog()
  };

  const handleRejection = () => {

    // Dispatch the rejection action
    dispatch(rejection_for_verifying_User({ userId: userId }, accessToken, toast));

    // Dispatch the resetUsers action
    dispatch(resetUsers(userId));
    setIsVerified(false)
    closeRejectDialog()
  };

  const handleUpdateSubscription = () => {
    dispatch(updateSubscription({ userId: userId, subscribe: !profileDetails.data.isSubscribed }, accessToken, toast));
    dispatch(getProfile(userId, accessToken))
    // setIsSubscribed(null)
    closeSubscribeDialog()
  }

  const openApproveDialog = () => setApproveDialogOpen(true);
  const closeApproveDialog = () => setApproveDialogOpen(false);

  const openRejectDialog = () => setRejectDialogOpen(true);
  const closeRejectDialog = () => setRejectDialogOpen(false);

  const openSubscribeDialog = () => setSubscribeDialogOpen(true);
  const closeSubscribeDialog = () => setSubscribeDialogOpen(false);

  const handleApproveButton = () => {
    // Handle approve logic
    openApproveDialog();
  };

  const handleSubscribeCheckbox = () => {
    openSubscribeDialog();
  }

  const handleRejectButton = () => {
    // Handle reject logic
    openRejectDialog();
  };

  if (profileDetailsLoading) {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }

  if (profileDetailsError) {
    return (
      <Center>
        <Text>{JSON.stringify(profileDetailsError)}</Text>
      </Center>
    );
  }

  if (profileDetails) {
    if (isVerified === null) {
      setIsVerified(profileDetails.data.isVerified);
    }
    // if (isSubscribed === null) {
    //   setIsSubscribed(profileDetails.data.isSubscribed)
    // }
    console.log(isVerified)
    return (
      <Box p={4} maxWidth="600px" mx="auto">
        <Flex align="center" mb={4}>
          <IconButton
            aria-label="Back"
            onClick={() => navigation(-1)}
            icon={<ArrowBackIcon />}
            mr={2}
          />
          <Text fontSize="2xl">Profile details</Text>
          <Badge colorScheme={isVerified ? 'green' : 'yellow'} ml={5} p={2} borderRadius="full">{isVerified ? 'Verified' : 'Pending Verification'}</Badge>
        </Flex>
        <Flex direction="column" align="center" mb={4}>
          <Image
            borderRadius="full"
            boxSize="100px"
            fit='cover'
            src={profileDetails.data.photos[0] ?? 'https://cdn.spark.love/blank-profile-picture.png'}
            alt="Profile Image"
            onClick={onOpen}
            cursor="pointer"
            mb={4}
          />
          <Text fontSize="xl">{profileDetails.data.firstName} {profileDetails.data.lastName}</Text>
        </Flex>
        <VStack spacing={4} align="stretch">
          <Divider />
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Email address</FormLabel>
            <Text>{profileDetails.data.email}</Text>
            {/* <Text ml={2} cursor='default' title={profileDetails.data.emailVerified ? 'Email Verified' : 'Pending Verification'}>{profileDetails.data.emailVerified ? '✅' : '⚠️'}</Text> */}
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Phone number</FormLabel>
            <Text>{profileDetails.data.countryCode} {profileDetails.data.phoneNumber}</Text>
            {/* <Text ml={2} cursor='default' title={profileDetails.data.phoneVerified ? 'Phone Verified' : 'Pending Verification'}>{profileDetails.data.phoneVerified ? '✅' : '⚠️'}</Text> */}
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">City</FormLabel>
            <Text>{profileDetails.data.userInfo.location}</Text>
          </FormControl>
          <Divider />
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Date of birth</FormLabel>
            <Text>{new Date(profileDetails.data.dateOfBirth).toLocaleDateString()}</Text>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Gender</FormLabel>
            <Text>{profileDetails.data.gender}</Text>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Bio</FormLabel>
            <Text>{profileDetails.data.bio}</Text>
          </FormControl>
          <Divider />
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Connected accounts</FormLabel>
            <HStack>
              <FaFacebook />
              <Text>{profileDetails.data.socialMedia?.facebook}</Text>
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0"></FormLabel>
            <HStack>
              <FaInstagram />
              <Text>{profileDetails.data.socialMedia?.instagram}</Text>
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0"></FormLabel>
            <HStack>
              <FaXTwitter />
              <Text>{profileDetails.data.socialMedia?.twitter}</Text>
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0"></FormLabel>
            <HStack>
              <FaLinkedin />
              <Text>{profileDetails.data.socialMedia?.linkedIn}</Text>
            </HStack>
          </FormControl>
          <Divider />
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Referral code</FormLabel>
            <Text>{profileDetails.data.invite?.code}</Text>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel minWidth="200px" mb="0">Membership status</FormLabel>
            <Checkbox colorScheme='green' onChange={handleSubscribeCheckbox} isChecked={profileDetails.data.isSubscribed} ></Checkbox>
            
          </FormControl>
        </VStack>
        <Flex mt={4} justify="right">
          <Button
            colorScheme="green"
            mr={8}
            onClick={handleApproveButton}
            isDisabled={isVerified === true}>
              Approve
          </Button>
          <Button
            colorScheme="red"
            onClick={handleRejectButton}
            isDisabled={isVerified === false}>
              Reject
          </Button>
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Carousel>
              {profileDetails.data.photos.length > 0 ? (
                profileDetails.data.photos.map((src, index) => (
                  <div key={index}>
                    <Image
                      borderRadius="5"
                      // boxSize="200px"
                      src={src}
                      alt={`Profile Image ${index + 1}`}
                      mx="auto"
                    />
                  </div>
                ))) : (
                  <div>
                    <Image
                      borderRadius="5"
                      // boxSize="200px"
                      src={'https://cdn.spark.love/blank-profile-picture.png'}
                      alt={`No Profile Image`}
                      mx="auto"
                    />
                  </div>
                )
              }
            </Carousel>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isApproveDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeApproveDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Approve Profile
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to approve this profile?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeApproveDialog}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleApproval} ml={3}>
                Approve
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isRejectDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeRejectDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reject Profile
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to reject this profile?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeRejectDialog}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleRejection} ml={3}>
                Reject
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={isSubscribeDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeSubscribeDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {profileDetails.data.isSubscribed ? 'Deactivate Subscription?' : 'Activate Subscription?'}
            </AlertDialogHeader>
            <AlertDialogBody>
              {profileDetails.data.isSubscribed ? 'Are you sure you want to deactivate subscription for this profile?' : 'Are you sure you want to activate subscription for this profile?'}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeSubscribeDialog}>
                Cancel
              </Button>
              <Button colorScheme={profileDetails.data.isSubscribed ? "red" : "green"} onClick={handleUpdateSubscription} ml={3}>
                {profileDetails.data.isSubscribed ? 'Deactivate' : 'Activate'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Box>
    );
  } else {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }
};

export default ProfilePage;
