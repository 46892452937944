import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Button,
  Flex,
  Center,
  Heading,
  Box,
  Text,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { approval_for_verifying_User, rejection_for_verifying_User, resetUsers } from '../../redux/userReducer/action';
import PaginationAllPage from '../../components/PaginationAllPage';
import { PageLoader } from '../../components/PageLoader';

const TableCard = ({ users, userId }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userData, setUserData] = useState([]);
  const PAGE_SIZE = 10;
  const { accessToken } = useSelector(state => state.authReducer);
  const { userIsLoading } = useSelector(store => store.userReducer);

  useEffect(() => {
    if (users && Array.isArray(users.data)) {
      const startIndex = (currentPage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      setUserData(users.data.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(users.data.length / PAGE_SIZE));
    }
  }, [users, currentPage]);
  const handleApproval = user => {
    const { _id } = user;

    // Dispatch the approval action
    dispatch(approval_for_verifying_User({ userId: _id }, accessToken, toast));

    // Dispatch the resetUsers action
    dispatch(resetUsers(_id));

    // Display a toast message
  };

  const handleRejection = user => {
    const { _id } = user;

    // Dispatch the rejection action
    dispatch(rejection_for_verifying_User({ userId: _id }, accessToken, toast));

    // Dispatch the resetUsers action
    dispatch(resetUsers(_id));
  };
  // console.log(userData);
  if (userIsLoading) {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      {userData?.length > 0 ? (
        <>
          <TableContainer borderWidth="1px" borderRadius="md" width={'full'} overflow="hidden">
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Images</Th>
                  <Th>Instagram Id</Th>
                  <Th>Bio</Th>
                  {/* <Th>Approval</Th>
                  <Th>Un-Approval</Th> */}
                  <Th>Profile</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userData?.length > 0 &&
                  userData?.map(el => (
                    <Tr key={el._id}>
                      <Td>{`${el.firstName ? el.firstName : ''} ${el.lastName ? el.lastName : ''}`}</Td>
                      <Td>
                        <img
                          src={
                            el.photos
                              ? el.photos
                              : 'https://t3.ftcdn.net/jpg/01/18/01/98/240_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg'
                          }
                          alt="UserImage"
                          style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                        />
                      </Td>
                      <Td>{el.insta_id}</Td>
                      <Td>
                        <Box h={100} w={300} overflowX="auto" maxHeight={100}>
                          <Text fontSize="md" lineHeight="tall">
                            {el.bio}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Link to={`/profile/${el._id}`}>
                          <Button
                          colorScheme="gray"
                          leftIcon={<ViewIcon />}
                          iconSpacing={0}
                          ></Button>
                        </Link>
                      </Td>
                      {/* <Td>
                        <Button
                          colorScheme="green"
                          onClick={() => handleApproval(el)}
                          disabled={el.isVerified === true}
                          leftIcon={<CheckIcon />}
                        ></Button>
                      </Td>
                      <Td>
                        <Button
                          colorScheme="red"
                          onClick={() => handleRejection(el)}
                          disabled={el.isVerified === false}
                          leftIcon={<CloseIcon />}
                        ></Button>
                      </Td> */}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <PaginationAllPage currentPage={currentPage} handlePageChange={setCurrentPage} totalPages={totalPages} />
        </>
      ) : (
        <Center>
          <Heading
            color={'orange'}
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}
          >
            No Data Found 😕
          </Heading>
        </Center>
      )}
    </Flex>
  );
};

export default TableCard;
