import axios from 'axios';
import { GET_ALL_WAITING_USER_FAIL, GET_ALL_WAITING_USER_REQUEST, GET_ALL_WAITING_USER_SUCCESS } from './actionTypes';
export const getAllWaitingUser = token => dispatch => {
  dispatch({ type: GET_ALL_WAITING_USER_REQUEST });
  return axios
    .get(`${process.env.REACT_APP_API_USER}/referral-users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      dispatch({ type: GET_ALL_WAITING_USER_SUCCESS, payload: res?.data?.data });
    })
    .catch(err => {
      dispatch({ type: GET_ALL_WAITING_USER_FAIL });
    });
};
