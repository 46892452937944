import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS } from './actionTypes';
import axios from 'axios';
// import 'dotenv/config'
// require('dotenv').config()
const api = process.env.REACT_APP_API_LOGIN;
const token = process.env.REACT_APP_API_LOGIN_TOKEN;
export const adminLogin = (data, toast) => dispatch => {
  dispatch({ type: LOGIN_REQUEST });
  return axios
    .post(api, data)
    .then(result => {
      // console.log(result.data.data.accessToken.accessTokenJWT);
      dispatch({ type: LOGIN_SUCCESS, payload: result.data.data.accessToken.accessTokenJWT });
      // console.log(result.data.data.accessToken);
      // localStorage.setItem('token', token);
    })
    .catch(err => {
      dispatch({ type: LOGIN_FAIL });
    });
};

export const defaultLogin = () => dispatch => {
  dispatch({ type: LOGIN_SUCCESS });
};

export const admingLogout = () => dispatch => {
  dispatch({ type: LOGOUT_REQUEST });
  setTimeout(() => {
    dispatch({ type: LOGOUT_SUCCESS, payload: '' });
  }, 1000);
};

export const adminNewLogin = () => dispatch => {
  // console.log(token);
  dispatch({ type: LOGIN_REQUEST });
  setTimeout(() => {
    dispatch({ type: LOGIN_SUCCESS, payload: token });
  }, 2000);
};
