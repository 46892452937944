import {
  // USER_VERIFY_FAILURE,
  USER_APPROVAL_VERIFY_SUCCESS,
  USER_REJECT_SUCCESS,
  REMOVE_REPORT_SUCCESS,
  DELETE_USER_SUCCESS,
  RESET_USERS,
  RESET_REPORTED_USERS,
  GET_ALL_USERS_FOR_VERIFY_REQUEST,
  GET_ALL_USERS_FOR_VERIFY_SUCCESS,
  GET_ALL_USERS_FOR_VERIFY_FAILURE,
  USER_APPROVAL_VERIFY_REQUEST,
  USER_APPROVAL_VERIFY_FAILURE,
  USER_VERIFY_REJECTION_REQUEST,
  USER_VERIFY_REJECTION_SUCCESS,
  USER_VERIFY_REJECTION_FAILURE,
  GET_ALL_REPORTED_USER_REQUEST,
  GET_ALL_REPORTED_USER_SUCCESS,
  GET_ALL_REPORTED_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  GET_PROFILE_FOR_VERIFY_SUCCESS,
  GET_PROFILE_FOR_VERIFY_REQUEST,
  GET_PROFILE_FOR_VERIFY_FAILURE,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILURE,
} from './actionTypes';

const initialState = {
  users: [],
  userIsLoading: false,
  userIsError: false,
  reportedUser: [],
  reportedUserIsLoading: false,
  reportedUserIsError: false,
  userApprovalIsLoading: false,
  userApprovalIsError: false,
  userRejectionIsLoading: false,
  userRejectionIsError: false,
  removeReportIsLoading: false,
  removeReportIsError: false,
  deleteUserIsLoading: false,
  deleteUserIsError: false,
  profileDetails: null,
  profileDetailsLoading: false,
  profileDetailsError: null,
  updateSubscriptionLoading: false,
  updateSubscriptionFailure: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_USERS_FOR_VERIFY_REQUEST:
      return { ...state, userIsLoading: true, userIsError: false };
    case GET_ALL_USERS_FOR_VERIFY_SUCCESS:
      return { ...state, userIsLoading: false, userIsError: false, users: payload };
    case GET_ALL_USERS_FOR_VERIFY_FAILURE:
      return { ...state, userIsLoading: false, userIsError: true };
    case USER_APPROVAL_VERIFY_REQUEST:
      return { ...state, userApprovalIsLoading: true, userApprovalIsError: false };
    case USER_APPROVAL_VERIFY_SUCCESS:
      return { ...state, userApprovalIsLoading: true, userApprovalIsError: false };
    case USER_APPROVAL_VERIFY_FAILURE:
      return { ...state, userApprovalIsLoading: false, userApprovalIsError: true };
    case USER_VERIFY_REJECTION_REQUEST:
      return { ...state, userRejectionIsLoading: true, userRejectionIsError: false };
    case USER_VERIFY_REJECTION_SUCCESS:
      return { ...state, userRejectionIsLoading: false, userRejectionIsError: false };
    case USER_VERIFY_REJECTION_FAILURE:
      return { ...state, userRejectionIsLoading: false, userRejectionIsError: true };
    case GET_ALL_REPORTED_USER_REQUEST:
      return { ...state, reportedUserIsLoading: true, reportedUserIsError: false };
    case GET_ALL_REPORTED_USER_SUCCESS:
      return { ...state, reportedUserIsLoading: false, reportedUserIsError: false, reportedUser: payload };
    case GET_ALL_REPORTED_USER_FAILURE:
      return { ...state, reportedUserIsLoading: false, reportedUserIsError: true };
    case DELETE_USER_REQUEST:
      return { ...state, deleteUserIsLoading: true, deleteUserIsError: false };
    case DELETE_USER_SUCCESS:
      return { ...state, deleteUserIsLoading: false, deleteUserIsError: false };
    case DELETE_USER_FAILURE:
      return { ...state, deleteUserIsLoading: false, deleteUserIsError: true };
    case USER_REJECT_SUCCESS:
      return { ...state, isLoading: false };
    case REMOVE_REPORT_SUCCESS:
      return { ...state, isLoading: false };
    case RESET_USERS:
      const updatedUsersData = state.users.data.filter(user => user._id !== payload);
      return { ...state, users: { ...state.users, data: updatedUsersData } };

    case RESET_REPORTED_USERS:
      // Filter out the reported user with the specified _id
      //   console.log("Before-reporteduser",state)
      const updatedReportedUser = state.reportedUser.data.filter(user => user._id !== payload);
      //   console.log("After-reporteduser", { ...state, reportedUser: { ...state.reportedUser, data: updatedReportedUser }})
      return { ...state, reportedUser: { ...state.reportedUser, data: updatedReportedUser } };
    case GET_PROFILE_FOR_VERIFY_REQUEST:
      return { ...state, profileDetails: null, profileDetailsLoading: true, profileDetailsError: null };
    case GET_PROFILE_FOR_VERIFY_SUCCESS:
      return { ...state, profileDetails: payload, profileDetailsLoading: false, profileDetailsError: null };
    case GET_PROFILE_FOR_VERIFY_FAILURE:
      return { ...state, profileDetails: null, profileDetailsLoading: false, profileDetailsError: payload };
    case SUBSCRIBE_USER_REQUEST:
      return { ...state, updateSubscriptionLoading: true, updateSubscriptionFailure: false };
    case SUBSCRIBE_USER_SUCCESS:
      return { ...state, updateSubscriptionLoading: false, updateSubscriptionFailure: false, profileDetails: payload };
    case SUBSCRIBE_USER_FAILURE:
      return { ...state, updateSubscriptionLoading: false, updateSubscriptionFailure: true };
    default:
      return state;
  }
};
