import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  // TableCaption,
  TableContainer,
  Box,
  Text,
  Image,
  Button,
  Center,
  Flex,
  HStack,
  useToast,
  Stack,
  Avatar,
  Heading,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import {
  archiveExperience,
  deleteExperience,
  getAllExperienceData,
  makeAvailableSchedule,
  makeUnAvailableSchedule,
} from '../../../redux/experiences/action';
import ButtonLoader from '../../../components/Loader';
import { globalData } from '../../../data/global';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Popup from '../../../components/PopUp';
import { PageLoader } from '../../../components/PageLoader';
import ReviwesComponent from './ExperienceInputCompoenents/Reviews';
import PaginationAllPage from '../../../components/PaginationAllPage';
import CalculateAvgRating from '../../../utlis/CalculateAvgrRating';
import PakageEditOption from './ExperienceInputCompoenents/PakageEditOption';
const PAGE_SIZE = 5;

const AllExperiencesTable = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.experienceReducer.allExperienceData);
  const getAllExperienceDataIsLoading = useSelector(state => state.experienceReducer.getAllExperienceDataIsLoading);
  const deleteLoader = useSelector(state => state.experienceReducer.deleteExperienceDataIsLoading);
  const archiveExperienceDataIsLoading = useSelector(state => state.experienceReducer.archiveExperienceDataIsLoading);
  const authData = useSelector(st => st.authReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const toast = useToast();
  const [experiences, setExperiences] = useState(data.experiences);
  const makeAvailableIsLoading = useSelector(state => state.experienceReducer.makeAvailableIsLoading);
  const makeUnAvailableIsLoading = useSelector(state => state.experienceReducer.makeUnAvailableIsLoading);
  const { accessToken } = useSelector(state => state.authReducer);
  const navigation = useNavigate();
  const { getAllExperienceDataIsError } = useSelector(state => state.experienceReducer);

  useEffect(() => {
    dispatch(getAllExperienceData(accessToken)).then(res => {
      if (getAllExperienceDataIsError) {
        navigation('/login');
      }
    });
  }, [
    authData,
    dispatch,
    archiveExperienceDataIsLoading,
    deleteLoader,
    makeAvailableIsLoading,
    makeUnAvailableIsLoading,
    accessToken,
    getAllExperienceDataIsError,
    navigation,
  ]);
  const deleteExperienceCb = id => {
    dispatch(deleteExperience(id, toast, accessToken));
  };
  useEffect(() => {
    if (data.experiences) {
      setExperiences(data.experiences.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE));
    }
  }, [data, currentPage]);

  const archiveExperienceData = id => {
    dispatch(archiveExperience(id, toast, accessToken));
  };

  const accendingSortExperienceByPrice = () => {
    const newExperienceData = data.experiences
      .sort((a, b) => a.price - b.price)
      .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
    // console.log(newExperienceData);
    setExperiences([...newExperienceData]);
  };

  const decendingSortExperienceByPrice = () => {
    const newExperienceData = data.experiences
      .sort((a, b) => b.price - a.price)
      .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
    setExperiences([...newExperienceData]);
  };

  // const accendingSortExperienceByAverageRating = () => {
  //   const newExperienceData = data.experiences.sort((a, b) => a.averageRating - b.averageRating).slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
  //   setExperiences([...newExperienceData])

  // }

  // const decendingSortExperienceByAverageRating = () => {
  //   const newExperienceData = data.experiences.sort((a, b) => b.averageRating - a.averageRating).slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
  //   setExperiences([...newExperienceData])

  // }

  const accendingSorExperienceCreatedAt = () => {
    // console.log('clicking');

    const newExperienceData = data.experiences
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    setExperiences([...newExperienceData]);
  };
  const accendingSorExperiencUpdateddAt = () => {
    // console.log('clicking');

    const newExperienceData = data.experiences
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    setExperiences([...newExperienceData]);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };
  // console.log(data.experiences);

  useEffect(() => {
    if (Array.isArray(data.experiences)) {
      setTotalPages(Math.ceil(data.experiences.length / PAGE_SIZE));
      // console.log();
    }
  }, [data]);

  // totalPages = Math.ceil(experiences.length / PAGE_SIZE)

  const makeAvailableScheduleCB = data => {
    dispatch(makeAvailableSchedule(accessToken, data, toast));
  };
  const makeUnAvailableScheduleCB = data => {
    dispatch(makeUnAvailableSchedule(accessToken, data, toast));
  };

  return (
    <Box m={5} p={5}>
      <Heading fontSize="2xl" color="teal.500" fontWeight="bold" textAlign="center" textTransform="uppercase" mt={4}>
        All the Experiences Are Here
      </Heading>
      {getAllExperienceDataIsLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Center>
            <HStack spacing={4} align="center">
              <div>
                <Button colorScheme="teal" size="sm" onClick={accendingSortExperienceByPrice} m={5}>
                  Ascending Sort By Price
                </Button>
                <Button colorScheme="teal" size="sm" onClick={decendingSortExperienceByPrice} m={5}>
                  Descending Sort By Price
                </Button>
              </div>
              {/* <div>
                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={accendingSortExperienceByAverageRating} m={5}
                >
                  Ascending Sort By Rating
                </Button>
                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={decendingSortExperienceByAverageRating} m={5}
                >
                  Descending Sort By Rating
                </Button>
             </div>*/}
              <div>
                <Button colorScheme="teal" size="sm" onClick={accendingSorExperienceCreatedAt} m={5}>
                  Show Most Recent Experiences
                </Button>
              </div>
              <div>
                <Button colorScheme="teal" size="sm" onClick={accendingSorExperiencUpdateddAt} m={5}>
                  Show Most Recent Updated Experiences
                </Button>
              </div>
            </HStack>
          </Center>

          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Sl No.</Th>
                  <Th>Name</Th>
                  <Th>Title</Th>
                  <Th>Description</Th>
                  <Th>Price</Th>
                  <Th>Show Price</Th>
                  <Th>Days Advance</Th>
                  <Th>Images</Th>
                  <Th>Videos</Th>
                  <Th>Location Name</Th>
                  <Th>Location</Th>
                  <Th>Included Packages</Th>
                  <Th>Available Schedule</Th>
                  <Th>Archived</Th>
                  <Th>Rating</Th>
                  <Th>All Reviwes</Th>
                  <Th>Add Review</Th>
                  <Td>Delete</Td>
                  <Td>Update</Td>
                  {/* Add additional headers for other properties as needed */}
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(experiences) &&
                  experiences?.map((experience, index) => (
                    <Tr key={experience._id}>
                      <Td>{(currentPage - 1) * PAGE_SIZE + index + 1}</Td>
                      <Td>{experience.name || 'Name'}</Td>
                      <Td>
                        <Box h={100} w={300} overflowX="auto" maxHeight={100}>
                          <Text fontSize="md" lineHeight="tall">
                            {experience.title}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box h={100} w={300} overflowX="auto" maxHeight={100}>
                          <Text fontSize="md" lineHeight="tall">
                            {experience.description}
                          </Text>
                        </Box>
                      </Td>
                      <Td>{experience.price}</Td>
                      <Td>{experience?.showPrice}</Td>
                      <Td>{experience?.bookAfter}</Td>
                      <Td>
                        <Popup
                          children={
                            <>
                              {experience.media.images?.map(item => (
                                <Box key={item._id} p={3} m={3}>
                                  <Image h={'100px'} w={'150px'} src={item.url} alt={item.title} />
                                </Box>
                              ))}
                            </>
                          }
                          modalTitle={'See All Images'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                      <Td>
                        <Popup
                          children={
                            <>
                              {experience.media.videos?.map(item => (
                                <Box key={item._id} p={3} m={3}>
                                  <iframe
                                    width="full"
                                    height="full"
                                    src={item.url}
                                    title="YouTube Video Player"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </Box>
                              ))}
                            </>
                          }
                          modalTitle={'See All Videos'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                      <Td>
                        <Box h={100} w={300} overflowX="auto" maxHeight={100}>
                          <Text fontSize="md" lineHeight="tall">
                            {experience.location ? experience.location.name : null}
                          </Text>
                        </Box>
                      </Td>

                      <Td>
                        {experience.location
                          ? ` Longitude :  ${experience.location.longitude},latitude :  ${experience.location.latitude}`
                          : 0}
                      </Td>

                      <Td>
                        <Popup
                          key={'somekye'}
                          children={experience.includedPackages?.map(item => (
                            <Box m={3} p={3} border={'1px solid blue'}>
                              <Text>
                                {' '}
                                <span style={{ fontWeight: 'bold' }}>Package Name</span> : {item.includedPackagesName}
                              </Text>
                              <Text>
                                {' '}
                                <span style={{ fontWeight: 'bold' }}>Package Description</span> :{' '}
                                {item.packagesDescription}
                              </Text>
                              <Text>
                                <span style={{ fontWeight: 'bold' }}>Package Category</span> : {item?.category}
                              </Text>
                              <Popup
                                key={'somekey2'}
                                modalTitle={'Edit'}
                                colorofModal={'teal'}
                                children={
                                  <PakageEditOption
                                    pkgDesc={item.packagesDescription}
                                    pkgName={item.includedPackagesName}
                                    pkgCategory={item.category}
                                    //onSubmit={handleEditPackageSubmit}
                                    experienceId={experience._id}
                                    pakageId={item._id}
                                  />
                                }
                              />
                            </Box>
                          ))}
                          modalTitle={'See All Included Packages'}
                          colorofModal={'teal'}
                        />
                      </Td>
                      <Td>
                        <Popup
                          children={experience.schedulesAvailable?.map(item => (
                            <Box key={item._id} border={'1px solid blue'} p={3} m={3}>
                              <Text>Day : {item.day}</Text>
                              <Text>
                                Time : {item.startTime} to {item.endTime}
                              </Text>
                              <Text>Total Slots Now : {item.slots}</Text>
                              <Text color={item.available ? 'green' : 'red'}>
                                Current Status: {item.available ? "It's Available" : "It's Not Available"}
                              </Text>
                              {item.available ? (
                                <Button
                                  onClick={() =>
                                    makeUnAvailableScheduleCB({
                                      bookingSlotId: item._id,
                                      experienceId: experience._id,
                                      availability: false,
                                    })
                                  }
                                  colorScheme="red"
                                >
                                  Make UnAvailable
                                </Button>
                              ) : (
                                <Button
                                  onClick={() =>
                                    makeAvailableScheduleCB({
                                      bookingSlotId: item._id,
                                      experienceId: experience._id,
                                      availability: true,
                                    })
                                  }
                                  colorScheme="green"
                                >
                                  Make Available
                                </Button>
                              )}
                            </Box>
                          ))}
                          modalTitle={'See all the Schedule'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                      <Td>{experience.archived ? "User Can't See" : 'User Can See'}</Td>
                      <Td>
                        <CalculateAvgRating rating={experience.rating} />
                      </Td>

                      <Td>
                        <Popup
                          children={experience.rating?.map(item =>
                            experience.rating?.length === 0 ? (
                              <Text>Not Reviews Till Now</Text>
                            ) : (
                              <Stack key={item._id} spacing={4} m={4}>
                                <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
                                  <Stack direction="row" spacing={4} align="center">
                                    <Avatar size="sm" src={item.image} alt={item.name} />
                                    <Box>
                                      <Text fontWeight="bold">{item.name}</Text>
                                      <Text fontSize="sm" color="gray.600">
                                        {new Date(item.time).toLocaleString()}
                                      </Text>
                                    </Box>
                                  </Stack>
                                  <Text mt={2}>{item.description}</Text>
                                </Box>
                              </Stack>
                            ),
                          )}
                          modalTitle={'See all the Reviews'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                      <Td>
                        <Popup
                          children={<ReviwesComponent id={experience._id} token={accessToken} />}
                          modalTitle={'Add New Review'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                      <Td>
                        <Popup
                          children={
                            <Flex gap={4} direction={'column'}>
                              <Text>Are You Confirm To Delete?</Text>
                              <Button w={'50%'} colorScheme="red" onClick={() => deleteExperienceCb(experience._id)}>
                                {deleteLoader ? <ButtonLoader h={40} w={40} /> : 'Delete'}
                              </Button>
                            </Flex>
                          }
                          modalTitle={'Delete'}
                          addtionMessage={'Make it Archive'}
                          colorofModal={globalData.buttonColorCancel}
                          addtionalButtonColor={globalData.buttonColorNormal}
                          additionalTask={() => archiveExperienceData(experience._id, toast)}
                        />
                      </Td>
                      <Td>
                        <Popup
                          children={
                            <>
                              <Text>Are You Confirm To Update Experience?</Text>
                              <Link to={`/update/experience/${experience._id}`}>
                                <Button colorScheme="blue">Update</Button>
                              </Link>
                            </>
                          }
                          modalTitle={'Update'}
                          colorofModal={globalData.buttonColorNormal}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Center>
            {/*currentPage, totalPages, handlePageChange*/}
            <PaginationAllPage currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
          </Center>
        </Box>
      )}
    </Box>
  );
};

export default AllExperiencesTable;
