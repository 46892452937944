import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import Popup from '../../components/PopUp';
import { eidtBookingStatus, getAllBookedExperiences } from '../../redux/bookingExperience/action';
import { PageLoader } from '../../components/PageLoader';
import PaginationAllPage from '../../components/PaginationAllPage';
import { useNavigate } from 'react-router-dom';
import TimeAgo from '../../components/Timeago';

const Booking = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector(state => state.authReducer);
  const bookingData = useSelector(state => state.bookingReducer);
  const getBookingDataIsLoading = useSelector(state => state.bookingReducer.getBookingDataIsLoading);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [bookingStatus, setBookingStatus] = useState('pending');
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [updatedAtSort, setUpdatedAtSort] = useState('');
  const [cansalationReason, setCansalationReason] = useState('');
  const [bookedForSort, setBookedForSort] = useState(null);
  const [bookedOnSort, setBookedOnSort] = useState('desc');
  const [toatalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const toast = useToast();
  const itemsPerPage = 5;
  const startSerialNumber = (page - 1) * itemsPerPage + 1;
  const navigate = useNavigate();
  const { getBookingDataIsLoadingError } = useSelector(st => st.bookingReducer);
  const handleStatusChange = value => {
    setSelectedStatus(value);
  };
  const bookingStatusOptions = ['pending', 'confirmed', 'cancelled'];
  const paymentStatusOptions = ['pending', 'success', 'cancelled'];

  useEffect(() => {
    if (bookedForSort) {
      setBookedOnSort(null);
    }
    if (bookedOnSort) {
      console.log('working', 'booking on sort');
      setBookedForSort(null);
    }
    dispatch(
      getAllBookedExperiences(
        accessToken,
        page,
        bookingStatus,
        paymentStatus,
        verificationStatus,
        bookedForSort,
        bookedOnSort,
        updatedAtSort,
      ),
    ).then(res => {
      if (getBookingDataIsLoadingError) {
        navigate('/login');
      }
    });
  }, [
    dispatch,
    accessToken,
    page,
    bookingStatus,
    paymentStatus,
    verificationStatus,
    bookedForSort,
    bookedOnSort,
    updatedAtSort,
    getBookingDataIsLoadingError,
    navigate,
  ]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handleBookingStatusChange = id => {
    const status = {
      bookingStatus: selectedStatus,
      bookingId: id,
      adminCancellationReason: cansalationReason,
    };
    dispatch(eidtBookingStatus(status, accessToken, toast)).then(() => {
      dispatch(
        getAllBookedExperiences(
          accessToken,
          page,
          bookingStatus,
          paymentStatus,
          verificationStatus,
          bookedForSort,
          bookedOnSort,
          updatedAtSort,
        ),
      );

      if (!getBookingDataIsLoading) {
        setCansalationReason('');
        setSelectedStatus('');
      }
    });
  };

  useEffect(() => {
    if (
      bookingData?.bookingData?.data?.results?.length === 0 ||
      bookingData?.bookingData?.data?.results?.length === undefined ||
      isNaN(bookingData?.bookingData?.data?.results?.length) ||
      bookingData?.bookingData?.data?.results?.length === null
    ) {
      setTotalPage(1);
    } else {
      // Calculate the total pages based on the number of items and itemsPerPage
      const totalPageCount = Math.ceil(bookingData?.bookingData?.data?.count / itemsPerPage);
      setTotalPage(totalPageCount);
    }
  }, [bookingData, itemsPerPage]);

  useEffect(() => {
    if (selectedStatus === 'cancelled') {
      toast({
        title: 'Once You Have Cancelled the booking you can not confirm it again.',
        description: '',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [selectedStatus, toast]);
  if (bookingData.getBookingDataIsLoading) {
    return <PageLoader />;
  }
  return (
    <Flex flexDir="column">
      <Center>
        <Heading m={3} p={3} color="green">
          Booking Experience Table
        </Heading>
      </Center>
      <Center p={4} borderRadius="md">
        <Flex gap={4} m={3} w="100%" alignItems="center" justifyContent="space-around">
          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="bookingStatus"
            >
              Filter By Booking Status
            </label>
            <Select mt={3} id="bookingStatus" value={bookingStatus} onChange={e => setBookingStatus(e.target.value)}>
              {bookingStatusOptions.map(status => (
                <option key={status} value={status}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="paymentStatus"
            >
              Filter By Payment Status
            </label>
            <Select mt={3} id="paymentStatus" value={paymentStatus} onChange={e => setPaymentStatus(e.target.value)}>
              {paymentStatusOptions.map(status => (
                <option key={status} value={status}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              ))}
            </Select>
          </Box>

          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="bookedOnSort"
            >
              Sort by Booked On Date
            </label>
            <Select mt={3} id="bookedOnSort" value={bookedOnSort} onChange={e => setBookedOnSort(e.target.value)}>
              <option value={null}>--Select The Value--</option>
              <option value="asc">Show Latest Bookings</option>
              <option value="desc">Default</option>
            </Select>
          </Box>

          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="bookedForSort"
            >
              Sort by Date on
            </label>
            <Select mt={3} id="bookedForSort" value={bookedForSort} onChange={e => setBookedForSort(e.target.value)}>
              <option value="asc">Ascending Sort</option>
              <option value="desc">Descending Sort</option>
            </Select>
          </Box>
          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="verificationStatus"
            >
              Filter By Admin Verification
            </label>
            <Select
              mt={3}
              id="verificationStatus"
              value={verificationStatus}
              onChange={e => setVerificationStatus(e.target.value)}
            >
              <option value={true}>Verified</option>
              <option value={false}>Not-Verified</option>
            </Select>
          </Box>
          <Box>
            <label
              style={{
                fontWeight: 'bold',
                margin: '3px',
              }}
              htmlFor="updatedAtSort"
            >
              Sorted By Updated Date
            </label>
            <Select mt={3} id="updatedAtSort" value={updatedAtSort} onChange={e => setUpdatedAtSort(e.target.value)}>
              <option value="asc">Ascending Order</option>
              <option value="desc">Descending Order</option>
            </Select>
          </Box>
        </Flex>
      </Center>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Sl No.</Th>
              <Th>Name</Th>
              <Th>Experience Title</Th>
              <Th>Booked On</Th>
              <Th>Dating On</Th>
              <Th>Booking Status</Th>
              <Th>Phone Number</Th>
              <Th>Email Id</Th>
              <Th>Payment Status</Th>
              <Th>User Cancellation Reason</Th>
              <Th>Admin Cancellation Reason</Th>
              <Th>Price</Th>
              <Th>Location Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(bookingData?.bookingData?.data?.results) &&
              bookingData?.bookingData?.data?.results?.map((item, index) => (
                <Tr key={item._id}>
                  <Td>{startSerialNumber + index}</Td>
                  <Td>{item.experience.name}</Td>
                  <Td>{item.experience.title}</Td>
                  <Td>{<TimeAgo item={item.createdAt} />}</Td>
                  <Td>{<TimeAgo item={item.bookedFor} />}</Td>
                  <Td>
                    {
                      <Box>
                        <Center>
                          <Text
                            color={
                              item.bookingStatus === 'pending'
                                ? 'blue'
                                : item.bookingStatus === 'cancelled'
                                ? 'red'
                                : 'green'
                            }
                            p={3}
                          >
                            {item.bookingStatus}
                          </Text>
                        </Center>
                        {item.bookingStatus === 'pending' && (
                          <Popup
                            children={
                              <Box p={4} borderWidth="1px" borderRadius="md">
                                <Heading mb={4} fontSize="xl">
                                  Select The Value
                                </Heading>
                                <Flex direction="column">
                                  <RadioGroup
                                    value={selectedStatus}
                                    p={4}
                                    onChange={value => handleStatusChange(value)}
                                  >
                                    <Stack direction="column">
                                      <Radio color={'green'} value="confirmed">
                                        Approve Booking
                                      </Radio>
                                      <Radio color={'red'} value="cancelled">
                                        Cancel the Booking
                                      </Radio>
                                    </Stack>
                                  </RadioGroup>
                                  {selectedStatus === 'confirmed' && (
                                    <Button colorScheme="green" onClick={() => handleBookingStatusChange(item._id)}>
                                      Submit
                                    </Button>
                                  )}
                                  {selectedStatus === 'cancelled' && (
                                    <Popup
                                      modalTitle={`Are you sure you want to cancel the booking?`}
                                      colorofModal={'red'}
                                      children={
                                        <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
                                          <Textarea
                                            value={cansalationReason}
                                            onChange={e => setCansalationReason(e.target.value)}
                                            placeholder="Enter the cancellation Reason"
                                          />
                                          <Button
                                            m={3}
                                            colorScheme="teal"
                                            w={'50%'}
                                            onClick={() => handleBookingStatusChange(item._id)}
                                          >
                                            Submit
                                          </Button>
                                        </Flex>
                                      }
                                    />
                                  )}
                                </Flex>
                              </Box>
                            }
                            modalTitle="Verify Booking Status"
                            colorofModal="green"
                          />
                        )}
                      </Box>
                    }
                  </Td>
                  <Td>{`${item.booker.countryCode}${item.booker.phoneNumber}`}</Td>
                  <Td>{item.booker.email}</Td>
                  <Td>
                    <Center>
                      <Text
                        p={3}
                        color={
                          item.paymentStatus === 'pending'
                            ? 'blue'
                            : item.paymentStatus === 'cancelled'
                            ? 'red'
                            : 'green'
                        }
                      >
                        {item.paymentStatus}
                      </Text>
                    </Center>
                  </Td>

                  <Td>{item.userCancellationReason}</Td>
                  <Td>{item.adminCancellationReason}</Td>
                  <Td>{}</Td>
                  <Td></Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Center>
        <PaginationAllPage currentPage={page} handlePageChange={handlePageChange} totalPages={toatalPage} />
      </Center>
    </Flex>
  );
};

export default Booking;
