import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../redux/userReducer/action';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import UserTable from './UserTable';

const UserList = () => {
  const dispatch = useDispatch();
  const { users: unverifiedUsers } = useSelector(store => store.userReducer);

  const allUsers = {data:[]}

  const { accessToken } = useSelector(state => state.authReducer);
  const navigation = useNavigate();
  const { userIsError } = useSelector(store => store.userReducer);

  useEffect(() => {
    dispatch(getUser(undefined, accessToken)).then(res => {
      if (userIsError) {
        navigation('/login');
      }
    });
  }, [dispatch, accessToken, userIsError, navigation]);

  return (
    <Tabs variant="soft-rounded" colorScheme="blue" defaultIndex={1}>
      <TabList borderBottom='1px solid lightgray'>
        <Tab _selected={{ borderBottom: '3px solid #2c9bf0', fontWeight: 'bold', borderRadius: 0 }}>All Users</Tab>
        <Tab _selected={{ borderBottom: '3px solid #2c9bf0', fontWeight: 'bold', borderRadius: 0 }}>Priority Lane</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <UserTable users={allUsers} />
        </TabPanel>
        <TabPanel>
          <UserTable users={unverifiedUsers} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default UserList;
