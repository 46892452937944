import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { singleExperience, updateExperienceData } from '../../../redux/experiences/action';
import { Box, Button, Center, FormControl, FormLabel, Input, Textarea, VStack, useToast, FormErrorMessage } from '@chakra-ui/react';
import HandleVideoUpload from './ExperienceInputCompoenents/HandleVidoeUpload';
import HandleImageUpload from './ExperienceInputCompoenents/HandleImageUpload';
import ButtonLoader from '../../../components/Loader';
import HandleIncludedPakages from './ExperienceInputCompoenents/HandleIncludedPagages';
import LocationComponent from './ExperienceInputCompoenents/Location';

const UpdateExperience = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.experienceReducer.singleExperienceData);
  const updateIsError = useSelector(state => state.experienceReducer.updateExperienceDataIsError);
  const updateIsLoading = useSelector(state => state.experienceReducer.updateExperienceDataIsLoading);

  const toast = useToast();
  const navigaion = useNavigate();

  const [updatedData, setUpdatedData] = useState({
    _id: '',
    title: '',
    price: '',
    showPrice: '',
    bookAfter: '',
    description: '',
    created_by: '',
    name: '',
    media: {
      videos: [
        {
          title: '',
          url: '',
        },
      ],
      images: [
        {
          url: '',
          title: '',
        },
      ],
    },
    includedPackages: [
      {
        includedPackagesName: '',
        packagesDescription: '',
        category: '',
      },
    ],
    bookingDetails: '',
    location: {
      longitude: 0,
      latitude: 0,
    },
  });
  const { accessToken } = useSelector(state => state.authReducer);
  useEffect(() => {
    dispatch(singleExperience(id, accessToken));
  }, [id, dispatch, accessToken]);

  useEffect(() => {
    setUpdatedData({
      _id: data._id || '',
      title: data.title || '',
      price: data.price || '',
      showPrice: data?.showPrice || '',
      bookAfter: data?.bookAfter || '',
      created_by: data.created_by || '',
      name: data.name || '',
      description: data.description || '',
      location: {
        longitude: data.location?.longitude,
        latitude: data.location?.latitude,
        name: data.location?.name,
      } || { longitude: 0, latitude: 0, name: '' },
      media: {
        videos: data.media?.videos?.map(video => {
          if (video.url === undefined && video.title === undefined) {
            return { url: '', title: '' };
          } else {
            return video;
          }
        }) || [{ url: '', title: '' }], // Set default if videos array is not present or empty

        images: data.media?.images.map(image => {
          if (image.url === undefined && image.title === undefined) {
            return { url: '', title: '' };
          } else {
            return image;
          }
        }) || [{ url: '', title: '' }], // Set default if images array is not present or empty
      },
      includedPackages: data.includedPackages?.map(item => ({
        includedPackagesName: item.includedPackagesName,
        packagesDescription: item.packagesDescription,
        category: item.category,
      })) || [
        {
          includedPackagesName: '',
          packagesDescription: '',
          category: '',
        },
      ],
      bookingDetails: data.bookingDetails,
    });
  }, [data]);

  const [error, setError] = useState([]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    // if (name === 'bookingDetails' && !value.match(/^[a-zA-Z0-9.,\r\n\s\n"']{0,1000}$/)) {
    //   setError(['bookingDetails'])
    // } else {
    //   setError((prev) => {
    //     prev.splice(prev.indexOf('bookingDetails'))
    //     return prev
    //   })
    // }

    // Parse value to number if the field is price, showPrice, or bookAfter
    const parsedValue = ['price', 'showPrice', 'bookAfter'].includes(name) ? parseFloat(value) : value;

    setUpdatedData(prevData => ({ ...prevData, [name]: parsedValue }));
  };

  const handleMediaChange = (mediaType, index, fieldName, value) => {
    const updatedMedia = [...updatedData.media[mediaType]];
    updatedMedia[index] = { ...updatedMedia[index], [fieldName]: value };

    setUpdatedData(prevData => ({
      ...prevData,
      media: { ...prevData.media, [mediaType]: updatedMedia },
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    //  console.log(updatedData.description, "des line 125");
    dispatch(updateExperienceData(id, updatedData, toast, accessToken));
    setTimeout(() => {
      if (!updateIsError) {
        // window.location.href = '/experience';
        navigaion('/experience');
      }
    }, 2000);
  };
  // console.log(updatedData);
  return (
    <Center>
      <VStack spacing={4} align="stretch">
        <Box>
          <h2>Update Experience</h2>
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel htmlFor="title">Title:</FormLabel>
                <Input type="text" id="title" name="title" value={updatedData.title} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="title">Category:</FormLabel>
                <Input type="text" id="name" name="name" value={updatedData.name} onChange={handleInputChange} />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="description">Description:</FormLabel>
                <Textarea
                  type="text"
                  id="title"
                  name="description"
                  value={updatedData.description}
                  onChange={handleInputChange}
                />
              </FormControl>
              <HandleIncludedPakages formData={updatedData} setFormData={setUpdatedData} />
              <FormControl>
                <FormLabel htmlFor="price">Price:</FormLabel>
                <Input type="number" id="price" name="price" value={updatedData.price} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormControl>Show Price</FormControl>
                <Input
                  type="number"
                  id="showPrice"
                  name="showPrice"
                  value={updatedData.showPrice}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="bookAfter">Days Advance:</FormLabel>
                <Input
                  type="number"
                  id="bookAfter"
                  name="bookAfter"
                  value={updatedData.bookAfter}
                  onChange={handleInputChange}
                />
              </FormControl>
            </VStack>
            <FormControl>
              <FormLabel htmlFor="price">Location:</FormLabel>
              <LocationComponent formData={updatedData} setFormData={setUpdatedData} toast={toast} />
            </FormControl>

            {/* Handle video and image uploads */}
            <HandleVideoUpload
              formData={updatedData}
              setFormData={setUpdatedData}
              id={updatedData._id}
              onVideoChange={(index, fieldName, value) => handleMediaChange('videos', index, fieldName, value)}
            />
            <HandleImageUpload
              formData={updatedData}
              setFormData={setUpdatedData}
              id={updatedData._id}
              onImageChange={(index, fieldName, value) => handleMediaChange('images', index, fieldName, value)}
            />
            <FormControl isInvalid={error.includes('bookingDetails')}>
                <FormLabel>Booking Details</FormLabel>
                <Textarea
                  type="text"
                  name="bookingDetails"
                  id="bookingDetails"
                  value={updatedData.bookingDetails}
                  onChange={handleInputChange}
                  placeholder="Enter booking details here"
                />
                <FormErrorMessage>special characters are not allowed</FormErrorMessage>
            </FormControl>
            <Center>
              <Button colorScheme="teal" type="submit" isDisabled={error.length > 0}>
                {updateIsLoading ? <ButtonLoader h={40} w={40} /> : 'Update Experience'}
              </Button>
            </Center>
          </form>
        </Box>
      </VStack>
    </Center>
  );
};

export default React.memo(UpdateExperience);
