import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const CalculateAvgRating = ({ rating }) => {
    const [avgRating, setAvgRating] = useState(0);

    const length = rating.length;

    useEffect(() => {
        if (length === 0) {
            setAvgRating(0);
            return;
        }

        const totalRating = rating.reduce(
            (accumulator, currentValue) => accumulator + currentValue.rating,
            0,
        );
        setAvgRating(Math.round(totalRating / length));
    }, [rating, length]);


    return (
        <Text>
            {avgRating}
        </Text>
    );
};

export default CalculateAvgRating;
