import React, { useEffect, useState } from 'react';
import { Button, Flex, Textarea, Heading, useToast, RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { singleExperience, updateExperienceData } from '../../../../redux/experiences/action';

const PakageEditOption = ({ pkgDesc, pkgName, pkgCategory, experienceId, pakageId }) => {
  const [desc, setDesc] = useState(pkgDesc);
  const [name, setName] = useState(pkgName);
  const [category, setCategory] = useState(pkgCategory);
  const { accessToken } = useSelector(st => st.authReducer);
  console.log(category);
  const dispatch = useDispatch();
  const toast = useToast();
  const updateIsError = useSelector(state => state.experienceReducer.updateExperienceDataIsError);

  const singleExperienceData = useSelector(state => state.experienceReducer.singleExperienceData);

  useEffect(() => {
    dispatch(singleExperience(experienceId, accessToken));
  }, [dispatch, accessToken, experienceId]);

  const handleSubmit = () => {
    const updatedPackageData = singleExperienceData?.includedPackages?.find(item => item._id === pakageId);

    if (updatedPackageData) {
      updatedPackageData.includedPackagesName = name;
      updatedPackageData.packagesDescription = desc;
      updatedPackageData.category = category;

      dispatch(updateExperienceData(experienceId, singleExperienceData, toast, accessToken));
    }
    setTimeout(() => {
      if (!updateIsError) {
        window.location.href = '/experience';
        //   navigaion("/experience");
      }
    }, 2000);
  };

  return (
    <div>
      <Flex flexDirection="column" gap={3}>
        <Heading as="h2" size="lg" mb={4}>
          Edit Package Option
        </Heading>

        <Heading as="h3" size="md" mb={2}>
          Package Name
        </Heading>
        <Textarea value={name} onChange={e => setName(e.target.value)} placeholder="Enter the package name" />

        <Heading as="h3" size="md" mb={2}>
          Package Description
        </Heading>
        <Textarea value={desc} onChange={e => setDesc(e.target.value)} placeholder="Enter the package description" />

        <Heading as="h3" size="md" mb={2}>
          Packages Category
        </Heading>
        <RadioGroup onChange={value => setCategory(value)} value={category}>
          <Stack direction="row">
            <Radio value="Food">Food</Radio>
            <Radio value="Drink">Drink</Radio>
            <Radio value="Spa">Spa</Radio>
            <Radio value="Other">Other</Radio>
          </Stack>
        </RadioGroup>

        <Button colorScheme="green" onClick={handleSubmit}>
          Submit
        </Button>
      </Flex>
    </div>
  );
};

export default PakageEditOption;
