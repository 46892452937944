export const loginCredintialChecker = (email, password, toast) => {
  if (!email || !password) {
    toast({
      title: 'All fields are required',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    return false;
  } else if (
    email === process.env.REACT_APP_API_LOGIN_EMAIL &&
    process.env.REACT_APP_API_LOGIN_PASSWORD === password
  ) {
    toast({
      title: 'Login success',
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });

    return true;
  } else {
    toast({
      title: 'Invalid credentials',
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
    return false;
  }
};
