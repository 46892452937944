import { GET_ALL_WAITING_USER_FAIL, GET_ALL_WAITING_USER_REQUEST, GET_ALL_WAITING_USER_SUCCESS } from './actionTypes';

const initialState = {
  waitingUserIsLoading: false,
  waitingUserIsError: false,
  waitingUser: [],
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_WAITING_USER_REQUEST:
      return { ...state, waitingUserIsLoading: true };

    case GET_ALL_WAITING_USER_SUCCESS:
      return { ...state, waitingUserIsLoading: false, waitingUserIsError: false, waitingUser: payload };
    case GET_ALL_WAITING_USER_FAIL:
      return { ...state, waitingUserIsLoading: false, waitingUserIsError: true };
    default:
      return { ...state };
  }
};
