import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  VStack,
  useToast,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  FormErrorMessage
} from '@chakra-ui/react';
import { globalData, loginData } from '../../../data/global';
import { useDispatch, useSelector } from 'react-redux';
import { postExperience, updateExperienceDataSecondStep } from '../../../redux/experiences/action';
import LocationComponent from './ExperienceInputCompoenents/Location';
import HandleImageUpload from './ExperienceInputCompoenents/HandleImageUpload';
import HandleVidoeUpload from './ExperienceInputCompoenents/HandleVidoeUpload';
import ButtonLoader from '../../../components/Loader';
import HandleIncludedPakages from './ExperienceInputCompoenents/HandleIncludedPagages';
import { validationExperienceFormData } from '../../../utlis/experienceValidator';

const ExperienceForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    title: '',
    price: '',
    showPrice: '',
    bookAfter: '',
    location_name: '',
    description: '',
    created_by: loginData.name,
    location: {
      longitude: 0,
      latitude: 0,
      name: '',
    },
    name: '',
    includedPackages: [
      {
        includedPackagesName: '',
        packagesDescription: '',
        category: '',
      },
    ],
    media: {
      videos: [
        {
          title: '',
          url: '',
        },
      ],
      images: [
        {
          url: '',
          title: '',
        },
      ],
    },
    bookingDetails: ''
  });
  const [finalFromData, setFinalFromData] = useState({
    media: {
      videos: [
        {
          title: '',
          url: '',
        },
      ],
      images: [
        {
          url: '',
          title: '',
        },
      ],
      bookingDetails: '',
    },
  });
  const [showHideSecondStep, setShowHideSecondStep] = useState(false);
  const { accessToken } = useSelector(st => st.authReducer);
  const dispatch = useDispatch();
  const toast = useToast();
  const loader = useSelector(state => state.experienceReducer.postExperienceDataIsLoading);
  const preGeneratedExperienceData = useSelector(state => state.experienceReducer.preGeneratedExpericeData);
  // const postExperienceDataIsLoading = useSelector(state=> state.experienceReducer.postExperienceDataIsLoading);
  const { _id } = preGeneratedExperienceData;
  const uploadedMediaScheduleIsLoading = useSelector(state => state.experienceReducer.uploadedMediaScheduleIsLoading);
  // console.log(_id);
  const handleSubmit = e => {
    e.preventDefault();
    const flag = validationExperienceFormData(formData, toast);
    // Add your logic to send the form data to the server or perform any other actions
    if (flag) {
      dispatch(postExperience(accessToken, formData, toast, setShowHideSecondStep)); // Assuming postExperience is your action creator
    }

    // You can also perform any other actions after form submission
    // For example, redirecting the user to another page
    // history.push('/success');
  };

  const handleFinalSubmit = e => {
    e.preventDefault();
    // console.log(finalFromData);
    // if (_id) {
    // console.log(finalFromData);
    dispatch(updateExperienceDataSecondStep(_id, finalFromData, toast, accessToken));

    setTimeout(() => {
      if (!uploadedMediaScheduleIsLoading) {
        onClose();
      }
    }, 2000);

    // }
  };

  const [error, setError] = useState([]);

  const handleChange = e => {
    const { name, value } = e.target;
    let newValue;
    if (
      name === 'reviews_count' ||
      name === 'rating' ||
      name === 'price' ||
      name === 'showPrice' ||
      name === 'bookAfter'
    ) {
      newValue = +value;
    } else {
      newValue = value;
    }
    setFormData(prevData => ({ ...prevData, [name]: newValue }));
  };

  const handleFinalChange = e => {
    const { name, value } = e.target;
    // if (name === 'bookingDetails' && !value.match(/^[a-zA-Z0-9.,\r\n\s\n"']{0,1000}$/)) {
    //   setError(['bookingDetails'])
    // } else {
    //   setError((prev) => {
    //     prev.splice(prev.indexOf('bookingDetails'))
    //     return prev
    //   })
    // }
    setFinalFromData(prevData => ({ ...prevData, [name]: value }));
  };

  return (
    <Box w={'60%'} m={'auto'} textAlign={'center'}>
      <form>
        <VStack spacing={4} align="stretch">
          {/* Title */}
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter the Title"
            />
          </FormControl>
          {/* Name */}
          <FormControl id="title">
            <FormLabel>Category</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter the Name" />
          </FormControl>

          {/* Price */}
          <FormControl id="price">
            <FormLabel>Price</FormLabel>
            <NumberInput
              name="price"
              value={formData.price}
              onChange={valueString => setFormData(prevData => ({ ...prevData, price: parseFloat(valueString) || 0 }))}
              placeholder="Enter price"
              min={0}
              max={2000000000000}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          {/* Show Price */}
          <FormControl id="showPrice">
            <FormLabel>Show Price</FormLabel>
            <Input
              type="number"
              name="showPrice"
              value={formData.showPrice}
              onChange={handleChange}
              placeholder="Enter the Show Price"
            />
          </FormControl>
          {/* Days Advance */}
          <FormControl id="bookAfter">
            <FormLabel>Days Advance</FormLabel>
            <Input
              type="number"
              name="bookAfter"
              value={formData.bookAfter}
              placeholder="Enter Days Advance"
              onChange={handleChange}
            />
          </FormControl>
          {/* Location Name */}
          <FormControl id="location_name">
            <FormLabel>Location Name</FormLabel>
            <LocationComponent formData={formData} setFormData={setFormData} toast={toast} />
          </FormControl>

          {/*Description */}
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter description here"
            />
          </FormControl>
          <HandleIncludedPakages formData={formData} setFormData={setFormData} />

          {!showHideSecondStep && (
            <Button onClick={handleSubmit} colorScheme={globalData.buttonColorNormal}>
              {loader ? <ButtonLoader w={40} h={40} /> : 'Complete First Step'}
            </Button>
          )}

          {showHideSecondStep && (
            <Box>
              {/* Media - Videos */}
              <HandleVidoeUpload id={_id} formData={finalFromData} setFormData={setFinalFromData} />

              {/* Media - Image URLs */}
              <HandleImageUpload id={_id} formData={finalFromData} setFormData={setFinalFromData} />

              {/*Description */}
              <FormControl isInvalid={error.includes('bookingDetails')}>
                <FormLabel>Booking Details</FormLabel>
                <Textarea
                  type="text"
                  name="bookingDetails"
                  value={finalFromData.bookingDetails}
                  onChange={handleFinalChange}
                  placeholder="Enter booking details here"
                />
                <FormErrorMessage>special characters are not allowed</FormErrorMessage>
              </FormControl>

              {/* Submit Button */}
              <Box>
                <Button onClick={handleFinalSubmit} colorScheme={globalData.buttonColorNormal} type="submit" isDisabled={error.length > 0}>
                  {uploadedMediaScheduleIsLoading ? <ButtonLoader w={40} h={40} /> : ' Submit'}
                </Button>
              </Box>
            </Box>
          )}
        </VStack>
      </form>
    </Box>
  );
};

export default ExperienceForm;
