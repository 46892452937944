import React, { useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Heading, Center, Box, Text } from '@chakra-ui/react';
import TimeAgo from '../../components/Timeago';
import { PageLoader } from '../../components/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWaitingUser } from '../../redux/waitingUsers/action';
const WaitingUsersList = () => {
  const waitingUser = useSelector(st => st.waitingUserReducer.waitingUser);
  const loader = useSelector(st => st.waitingUserReducer.waitingUserIsLoading);
  const error = useSelector(st => st.waitingUserReducer.waitingUserIsError);

  const token = process.env.REACT_APP_API_LOGIN_TOKEN;
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWaitingUser(token)).then(res => {
      if (error) {
        navigation('/login');
      }
    });
  }, [dispatch, token, navigation, error]);
  if (loader) {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }
  if (error) {
    return (
      <Center>
        <Heading color={'red'}>Someting Went To Wrong</Heading>
      </Center>
    );
  }
  return (
    <div>
      <Heading color={'blue'} as={Center}>
        Wating Users List
      </Heading>
      <TableContainer>
        <Table variant="small">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email Id</Th>
              <Th isNumeric>VIP Or NOT</Th>
              <Th>Mobile Number</Th>
              <Th>Referral Code</Th>
              <Th>Pending VIP Status</Th>
              <Th>Phone Verification</Th>
              <Th>Created At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(waitingUser) &&
              waitingUser.length > 0 &&
              waitingUser.map(user => (
                <Tr key={user._id}>
                  <Td>
                    <Box h={30} w={150} overflowX="auto" maxHeight={100}>
                      <Text fontSize="md" lineHeight="tall">
                        {user.name}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box h={30} w={150} overflowX="auto" maxHeight={100}>
                      <Text fontSize="md" lineHeight="tall">
                        {user.email}
                      </Text>
                    </Box>
                  </Td>
                  <Td>{user.isVIP ? 'VIP' : 'NOT VIP'}</Td>
                  <Td>{user.phoneNumber ? `${user.countryCode} ${user.phoneNumber}` : '-'}</Td>
                  <Td>{user.referralCode ? user.referralCode : 'NA'}</Td>
                  <Td>{user.pendingVipStatus ? 'Pending' : 'Verified'}</Td>
                  <Td>{user.phoneVerified ? 'Yes' : 'No'}</Td>
                  <Td>
                    <TimeAgo item={user.createdAt} />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WaitingUsersList;
