import axios from 'axios';

import {
  EDIT_BOOKINGSTATUS_FAIL,
  EDIT_BOOKINGSTATUS_REQUEST,
  EDIT_BOOKINGSTATUS_SUCESS,
  GET_ALL_BOOKINGDATA_FAIL,
  GET_ALL_BOOKINGDATA_REQUEST,
  GET_ALL_BOOKINGDATA_SUCESS,
} from './actionType';
const api = process.env.REACT_APP_API_EXPERIENCE;

export const getAllBookedExperiences =
  (token, page, bookingStatus, paymentStatus, verificationStatus, bookedForSort, bookedOnSort, updatedAt) =>
  dispatch => {
    dispatch({ type: GET_ALL_BOOKINGDATA_REQUEST });

    let bookingApi = `${api}/booking?`;

    if (bookingStatus) {
      bookingApi += `bookingStatus=${bookingStatus}&`;
    }

    if (paymentStatus) {
      bookingApi += `paymentStatus=${paymentStatus}&`;
    }

    if (verificationStatus !== undefined && verificationStatus !== null) {
      bookingApi += `adminReviewed=${verificationStatus}&`;
    }

    if (bookedForSort) {
      bookingApi += `bookedFor=${bookedForSort}&`;
    }

    if (bookedOnSort) {
      bookingApi += `createdAt=${bookedOnSort}&`;
    }

    if (updatedAt) {
      bookingApi += `updatedAt=${updatedAt}&`;
    }

    bookingApi += `page=${page}&limit=5`;

    return axios
      .get(bookingApi, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': `application/json`,
        },
      })
      .then(res => {
        dispatch({ type: GET_ALL_BOOKINGDATA_SUCESS, payload: res.data });
      })
      .catch(err => {
        dispatch({ type: GET_ALL_BOOKINGDATA_FAIL });
      });
  };

// edit booking opetion

export const eidtBookingStatus = (data, token, toast) => dispatch => {
  dispatch({ type: EDIT_BOOKINGSTATUS_REQUEST });
  // console.log(data);
  return axios
    .post(`${api}/booking/review`, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      // console.log(res.data);
      dispatch({ type: EDIT_BOOKINGSTATUS_SUCESS });
      toast({
        title: res.data.message,
        description: data.bookingStatus,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    })
    .catch(err => {
      toast({
        title: err.message || err,
        description: data.bookingStatus,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      // console.log(err);
      dispatch({ type: EDIT_BOOKINGSTATUS_FAIL });
    });
};
