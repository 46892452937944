import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useToast,
  Flex,
  Center,
  Heading,
  Box,
  Text,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getAllReportedData, remove_report, resetReportedUsers } from '../../redux/userReducer/action';
import PaginationAllPage from '../../components/PaginationAllPage';

const PAGE_SIZE = 10;

const TableCard2 = ({ reportedUser }) => {
  // console.log(reportedUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { accessToken } = useSelector(state => state.authReducer);
  const [filteredReportedData, setFilteredReportedData] = useState([]);
  const dispatch = useDispatch();
  const toast = useToast();

  const handleDeleteUser = _id => {
    const data = { userId: _id };
    dispatch(deleteUser(accessToken, data, toast)).then(res => {
      dispatch(resetReportedUsers(_id));
    });
  };

  const handleRemoveReport = (reportedId, reporterId) => {
    const data = { reportedId, reporterId };
    dispatch(remove_report(accessToken, data, toast)).then(res => {
      dispatch(getAllReportedData(accessToken));
    });
  };
  useEffect(() => {
    let newReportedUser = [];
    if (reportedUser && Array.isArray(reportedUser)) {
      for (let i = 0; i < reportedUser.length; i++) {
        const { _id, reported, ...rest } = reportedUser[i];
        let count = reported?.length;
        for (let j = 0; j < reported?.length; j++) {
          const obj = {
            _id: _id,
            ...rest,
            reported: reported[j],
          };
          obj.count = count;
          if (obj.reported.status !== 'inactive') {
            newReportedUser.push(obj);
          }
        }
      }
    }
    if (reportedUser && Array.isArray(newReportedUser)) {
      const startIndex = (currentPage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      setFilteredReportedData(newReportedUser?.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(newReportedUser?.length / PAGE_SIZE));
    }
  }, [reportedUser, currentPage]);

  if (filteredReportedData.length === 0) {
    return (
      <Center>
        <Heading>No Reported Data</Heading>
      </Center>
    );
  }
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <TableContainer borderWidth="1px" borderRadius="md" width={'full'} overflow="hidden">
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Reported By</Th>
              <Th>Message</Th>
              <Th>Total Reported</Th>
              <Th>Remove Report</Th>
              <Th>Delete User</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(filteredReportedData) &&
              filteredReportedData.length > 0 &&
              filteredReportedData.map((user, index) => (
                <Tr key={user._id}>
                  <Td>{`${user?.firstName || ''} ${user?.lastName || ''}`}</Td>
                  <Td>{`${user.reported?.reportedBy?.firstName} ${user.reported?.reportedBy?.lastName}`}</Td>
                  <Td>
                    <Box h={100} w={300} overflowX="auto" maxHeight={100}>
                      <Text fontSize="md" lineHeight="tall">
                        {user.reported.message}
                      </Text>
                    </Box>
                  </Td>
                  <Td
                    color={
                      user.count >= 0 && user.count <= 2
                        ? 'green'
                        : user.count > 2 && user.count <= 4
                        ? 'orange'
                        : 'red'
                    }
                  >
                    {user.count}
                  </Td>
                  <Td>
                    <IconButton
                      icon={<CloseIcon />}
                      colorScheme={'red'}
                      aria-label={'Rejected'}
                      onClick={() => handleRemoveReport(user._id, user.reported?.reportedBy?.id)}
                    />
                  </Td>
                  <Td>
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      aria-label="Delete"
                      mr={2}
                      onClick={() => handleDeleteUser(user._id)}
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationAllPage currentPage={currentPage} handlePageChange={setCurrentPage} totalPages={totalPages} />
    </Flex>
  );
};

export default TableCard2;
