import TableCard2 from './TableCard2';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReportedData } from '../../redux/userReducer/action';
import { Box, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PageLoader } from '../../components/PageLoader';

const ReportedUser = () => {
  const dispatch = useDispatch();
  const { reportedUser } = useSelector(store => store.userReducer);
  const { accessToken } = useSelector(state => state.authReducer);
  // console.log({ "report": reportedUser });

  const { reportedUserIsError } = useSelector(state => state.userReducer);
  const { reportedUserIsLoading } = useSelector(state => state.userReducer);
  const navigation = useNavigate();
  useEffect(() => {
    dispatch(getAllReportedData(accessToken)).then(res => {
      if (reportedUserIsError) {
        navigation('/login');
      }
    });
  }, [dispatch, accessToken, navigation, reportedUserIsError]);
  if (reportedUserIsLoading) {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }
  return (
    <Box>
      <TableCard2 reportedUser={reportedUser.data} />
    </Box>
  );
};

export default ReportedUser;
