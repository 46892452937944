import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Experiences from '../pages/Experiences/Experiences';
import Login from '../pages/Login/Login';
import ReportedUser from '../pages/Reported_User/ReportedUser';
import FemalePage from '../pages/Verify_User/FemalePage';
import MalePage from '../pages/Verify_User/MalePage';
import BinaryPage from '../pages/Verify_User/BinaryPage';
import UpdateExperience from '../pages/Experiences/ExperiencesComponents/UpdateExperience';
import { PrivateRoute } from './PrivateRoute';
import Booking from '../pages/BookedExperience/BookingExperience';
import Home from '../pages/Landing/Home';
import WaitingUsersList from '../pages/WaitingUsersList/WaitingUsersList';
import ProfilePage from '../pages/Users_List/ProfilePage';
import UserList from '../pages/Users_List/UserList';
// import Booking from '../pages/BookingExperience/BookingExperience';

const AllRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/experience"
          element={
            <PrivateRoute>
              <Experiences />
            </PrivateRoute>
          }
        />
        <Route
          path="/update/experience/:id"
          element={
            <PrivateRoute>
              <UpdateExperience />
            </PrivateRoute>
          }
        />
        <Route
          path="/reported"
          element={
            <PrivateRoute>
              <ReportedUser />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/female"
          element={
            <PrivateRoute>
              <FemalePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/male"
          element={
            <PrivateRoute>
              <MalePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/binary"
          element={
            <PrivateRoute>
              <BinaryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/booking"
          element={
            <PrivateRoute>
              <Booking />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Home />} />
        <Route
          path="/waiting-users"
          element={
            <PrivateRoute>
              <WaitingUsersList />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default AllRouter;
