import React, { useEffect, useState } from 'react';
import { Center, TableContainer, Table, Thead, Tbody, Tr, Th, Td, Flex, Badge, Heading, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import PaginationAllPage from '../../components/PaginationAllPage';
import { PageLoader } from '../../components/PageLoader';
import { Link, useNavigate } from 'react-router-dom';

const statusBadgeColor = (status) => {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'yellow';
    // case 'Rejected':
    //   return 'red';
    // case 'Active':
    //   return 'green';
    // case 'Expired':
    //   return 'purple';
    // case 'Suspended':
    //   return 'orange';
    // case 'Deleted':
    //   return 'red';
    default:
      return 'gray';
  }
};

const UserTable = ({ users }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userData, setUserData] = useState([]);
  const PAGE_SIZE = 10;
  const { accessToken } = useSelector(state => state.authReducer);
  const { userIsLoading } = useSelector(store => store.userReducer);
  const navigate = useNavigate();
  useEffect(() => {
    if (users && Array.isArray(users.data)) {
      const startIndex = (currentPage - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      setUserData(users.data.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(users.data.length / PAGE_SIZE));
    }
  }, [users, currentPage]);

  if (userIsLoading) {
    return (
      <Center>
        <PageLoader />
      </Center>
    );
  }
  console.log(totalPages, currentPage)
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
    {userData?.length > 0 ? (
        <>
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Gender</Th>
            <Th>Email</Th>
            <Th>Mobile Number</Th>
            <Th>User Status</Th>
            <Th>Membership</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userData.map((user, index) => (
            <Tr key={index} onClick={() => navigate(`/profile/${user._id}`)} _hover={{ background: 'gray.100' }} style={{ textDecoration: 'none' }} cursor='pointer'>
              <Td>{user.firstName} {user.lastName}</Td>
              <Td>{user.gender}</Td>
              <Td>{user.email}</Td>
              <Td>{user.countryCode} {user.phoneNumber}</Td>
              <Td>
                <Badge colorScheme={statusBadgeColor(user.isVerified)}>
                  {user.isVerified ? 'Verified' : 'Pending Verification'}
                </Badge>
              </Td>
              <Td>{user.isSubscribed ? 'Paid' : 'Unpaid'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      </TableContainer>
      <PaginationAllPage currentPage={currentPage} handlePageChange={setCurrentPage} totalPages={totalPages} />
            </>
        ) : (
          <Center>
            <Heading
              color={'orange'}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}
            >
              No Data Found 😕
            </Heading>
          </Center>
        )}
    </Flex>
  );
};

export default UserTable;