//GET ALL USER
export const GET_ALL_USERS_FOR_VERIFY_REQUEST = 'GET_ALL_USERS_FOR_VERIFY_REQUEST';
export const GET_ALL_USERS_FOR_VERIFY_SUCCESS = 'GET_ALL_USERS_FOR_VERIFY_SUCCESS';
export const GET_ALL_USERS_FOR_VERIFY_FAILURE = 'GET_ALL_USERS_FOR_VERIFY_FAILURE';

// USER_VERIFY
export const USER_VERIFY_REJECTION_REQUEST = 'USER_VERIFY_REJECTION_REQUEST';
export const USER_VERIFY_REJECTION_FAILURE = 'USER_VERIFY_REJECTION_FAILURE';
export const USER_VERIFY_REJECTION_SUCCESS = 'USER_VERIFY_REJECTION_SUCCESS';

export const REPORTEDBY_REQUEST = 'REPORTEDBY_REQUEST';
export const REPORTEDBY_SUCCESS = 'REPORTEDBY_SUCCESS';
export const REPORTEDBY_FAILURE = 'REPORTEDBY_FAILURE';

export const USER_APPROVAL_VERIFY_REQUEST = 'USER_APPROVAL_VERIFY_REQUEST';
export const USER_APPROVAL_VERIFY_SUCCESS = 'USER_APPROVAL_VERIFY_SUCCESS';
export const USER_APPROVAL_VERIFY_FAILURE = 'USER_APPROVAL_VERIFY_FAILURE';

export const USER_REJECT_REQUEST = 'USER_REJECT_REQUEST';
export const USER_REJECT_SUCCESS = 'USER_REJECT_SUCCESS';
export const USER_REJECT_FAILURE = 'USER_REJECT_FAILURE';

export const REMOVE_REPORT_REQUEST = 'REMOVE_REPORT_REQUEST';
export const REMOVE_REPORT_SUCCESS = 'REMOVE_REPORT_SUCCESS';
export const REMOVE_REPORT_FAILURE = 'REMOVE_REPORT_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_ALL_REPORTED_USER_REQUEST = 'GET_ALL_REPORTED_USER_REQUEST';
export const GET_ALL_REPORTED_USER_SUCCESS = 'GET_ALL_REPORTED_USER_SUCCESS';
export const GET_ALL_REPORTED_USER_FAILURE = 'GET_ALL_REPORTED_USER_FAILURE';

export const RESET_USERS = 'RESET_USERS';

export const RESET_REPORTED_USERS = 'RESET_REPORTED_USERS';

export const GET_PROFILE_FOR_VERIFY_REQUEST = 'GET_PROFILE_FOR_VERIFY_REQUEST';
export const GET_PROFILE_FOR_VERIFY_SUCCESS = 'GET_PROFILE_FOR_VERIFY_SUCCESS';
export const GET_PROFILE_FOR_VERIFY_FAILURE = 'GET_PROFILE_FOR_VERIFY_FAILURE';

export const SUBSCRIBE_USER_REQUEST = 'SUBSCRIBE_USER_REQUEST';
export const SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS';
export const SUBSCRIBE_USER_FAILURE = 'SUBSCRIBE_USER_FAILURE';
