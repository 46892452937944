import { Text } from '@chakra-ui/react';
import React from 'react';

const TimeAgo = ({ item }) => {
  const bookedDate = new Date(item);

  const currentDate = new Date();

  const isPast = bookedDate < currentDate;

  const timeDifference = Math.abs(currentDate - bookedDate);

  const secondsDifference = Math.floor(timeDifference / 1000);

  const minutes = Math.floor(secondsDifference / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  // Choose the appropriate unit based on the time difference
  let result;
  if (isPast) {
    if (years > 0) {
      result = `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      result = `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (weeks > 0) {
      result = `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      result = `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      result = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      result = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      result = 'Just now';
    }
  } else {
    if (years > 0) {
      result = `${years} year${years > 1 ? 's' : ''} from now`;
    } else if (months > 0) {
      result = `${months} month${months > 1 ? 's' : ''} from now`;
    } else if (weeks > 0) {
      result = `${weeks} week${weeks > 1 ? 's' : ''} from now`;
    } else if (days > 0) {
      result = `${days} day${days > 1 ? 's' : ''} from now`;
    } else if (hours > 0) {
      result = `${hours} hour${hours > 1 ? 's' : ''} from now`;
    } else if (minutes > 0) {
      result = `${minutes} minute${minutes > 1 ? 's' : ''} from now`;
    } else {
      result = 'Just now';
    }
  }

  return (
    <div>
      <Text color={isPast ? 'green.400' : 'blue.400'}>{result}</Text>
    </div>
  );
};

export default TimeAgo;
